@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../styles.scss";
@import "../../../styles/breakpoints.scss";

#--text-fields {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-12);
  margin-right: rem(-12);

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  > * {
    width: calc(50% - #{rem(56)});
    margin-left: rem(12);
    margin-right: rem(12);
    margin-bottom: rem(24);

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  @include h4;
  @include article;


  .text-fields,
  .text-fields-sizes,
  .text-field-validation,
  .text-area-box {
    aside {
      > :not(:last-child) {
        margin-bottom: rem(16);
      }
    }
  }
}
