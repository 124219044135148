@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.uploading-files {
  height: rem(180);
  border: rem(1) solid map-get($color_gray, '_0');
  border-radius: rem(4);
  box-shadow: 0 0 rem(4) 0 map-get($color_black, '_4');

  &[disabled] {
    *,
    + aside {
      pointer-events: none;
    }

    .dropzone,
    + aside {
      opacity: 0.8;
    }
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: rem(16);
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;

    > .circle {
      border-radius: rem(50);
    }

    > .description {
      margin-top: rem(8);

      p {
        margin-top: 0; 
      }
    }

    > p {
      margin-top: rem(8);

      strong {
        font-weight: 400;
      }
    }
  }

+ aside {
    display: flex;
    margin: rem(-8);
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-direction: column;
      width: calc(33.33% - #{rem(16)});
      margin: rem(8);
      box-shadow: 0 0 rem(4) rem(0) map-get($color_black, '_3');
      border-radius: rem(4);
      overflow: hidden;

      p {
        text-align: center;
        padding: rem(16);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      figure {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;
        border-bottom: rem(1) solid map-get($color_gray, '_0');
        
        &.docx,
        &.doc,
        &.pdf {
          background-size: 70%;
        }

        &.docx {
          background-image: url('../../assets/images/docx.svg');
        }

        &.doc {
          background-image: url('../../assets/images/doc.svg');
        }

        &.pdf {
          background-image: url('../../assets/images/pdf.svg');
        }
  
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        &:hover {
          > .hidden-section {
            opacity: 1;
          }
        }

        .hidden-section {
          transition: 0.2s;
          background: map-get($color_black, '_2');
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            background-color: transparent;
            border: 0;
            position: absolute;
            top: rem(12);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              transition: 0.2s;
            }

            &:hover {
              svg {
                fill: map-get($color_white, '_0');
              }
            }
          }
        }
      }
    }
  }
}