@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

#--splash {
  display: flex;
  background-color: map-get($color_white, "_1");
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;

    svg {
      transform: rotate(360deg);
      animation: logo 1.3s ease-in-out infinite;
    }
  }
}

@keyframes logo {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}