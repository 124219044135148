@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../styles.scss";
@import "../../../styles/breakpoints.scss";

#--drawer {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-12);
  margin-right: rem(-12);

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  > * {
    width: calc(50% - #{rem(56)});
    margin-left: rem(12);
    margin-right: rem(12);
    margin-bottom: rem(24);

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  @include h4;
  @include article;

  .drawer-example {
    aside {
      display: flex;

      > :first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      > :last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

#--drawer-left-example-content,
#--drawer-right-example-content {
  padding: rem(24);
  width: rem(420);
  box-sizing: border-box;
  height: 100vh;

  h2 {
    font-size: rem(20);
    font-weight: 500;
    border-bottom: rem(1) solid map-get($color_gray, '_3');
    margin: 0 rem(-24);
    margin-top: rem(-24);
    padding: rem(16) rem(24);
  }

  button {
    width: 100%;
  }

  > :not(:last-child) {
    margin-bottom: rem(24);
  }
}