@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "../../styles/mainTitle.scss";

%margin {
  margin-left: auto;
  margin-right: auto;
}

.dashboard-page {
  .container-for-header {
    display: flex;
    position: relative;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    flex-direction: row;
    margin-bottom: rem(30);
  }
}

.container-for-content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  left: 0;
  right: 0;

  .container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: rem(190);
    height: rem(120);
    h4 {
      font-weight: 400;
      color: map-get($color_text, secondary);
      width: revert;
    }

    h1 {
      font-size: rem(45);
      width: revert;
      color: map-get($color_text, primary);

      .number-skeleton {
      }
    }
  }
}

.container-for-dashboard {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
}
.paper-skeleton-for-dashboard {
  height: rem(50);
}
