@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.our-story-container {
  width: 100%;

  .footer {
    margin-top: rem(20);
  }
}
