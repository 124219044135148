@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

.input-container {
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  background: map-get($color_white, '_0');
  border: 0;
  border-radius: rem(12);

  input {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: rem(16);
    line-height: rem(20);
    color: map-get($color_black, '_0');
    width: 100%;
    border: none;
    background-color: transparent;
    transition: 0.2s;
    outline: none;
    padding: 0;
    padding-left: rem(12);
    padding-right: rem(32);
    z-index: 2;

    &.password {
      letter-spacing: rem(4);
      font-weight: 900;
      font-size: rem(18);
    }

    &:focus {
      outline: none;
      transform: translate(0, 40%);
      height: 50%;
      & + label {
        transform: translate(0, -40%);
        font-size: rem(14);
        border-radius: rem(0);
        font-weight: 400;
        color: map-get($color_text, 'secondary');
      }
    }

    &:not(:placeholder-shown) {
      transform: translate(0, 40%);
      height: 50%;
      & + label {
        transform: translate(0, -40%);
        font-size: rem(14);
        border-radius: rem(0);
        font-weight: 400;
        color: map-get($color_text, 'secondary');
      }
    }
  }

  label {
    position: absolute;
    z-index: 1;
    left: rem(12);
    transition: 0.2s;
    border-radius: rem(0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  > .is-danger {
    display: flex;
    position: absolute;
    right: rem(10);
  }

  > .is-valid {
    display: flex;
    position: absolute;
    right: rem(12);
  }

  .right-icon {
    display: flex;
    right: rem(10);
    position: relative;
    z-index: 10;
  }

  &.default {
    border: rem(1) solid map-get($color_text, 'default');

    > label {
      color: map-get($color_text, 'default');
    }
  }

  &.primary {
    border: rem(1) solid map-get($color_black, '_1');

    > label {
      color: map-get($color_black, '_1');
    }
  }

  &.secondary {
    border: rem(1) solid map-get($color_green, '_0');

    > label {
      color: map-get($color_green, '_0');
    }
  }

  &.danger {
    border: rem(1) solid map-get($color_red, '_0');

    > label {
      color: map-get($color_red, '_0');
    }
  }

  &[disabled] {
    border: rem(1) solid map-get($color_gray, '_4');

    > label {
      color: map-get($color_gray, '_4');
    }

    input {
      pointer-events: none;
      color: map-get($color_gray, '_4');
    }
  }

  &.small {
    height: rem(34);

    > label {
      top: rem(8);
    }
  }

  &.medium {
    height: rem(38);

    > label {
      top: rem(10);
    }
  }

  &.large {
    height: rem(56);

    > label {
    }
  }
}
