@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.textarea-container {
  position: relative;
  z-index: 1;
  display: flex;
  background: map-get($color_white, "_0");
  width: 100%;
  border: 0;
  border-radius: rem(13);

  textarea {
    font-family: "Roboto";
    font-weight: 300;
    font-size: rem(16);
    line-height: rem(20);
    color: map-get($color_black, "_0");
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
    margin-top: rem(15);
    padding-left: rem(12);
    padding-right: rem(32);
    z-index: 2;
    height: rem(80);
    min-height: rem(52);
    max-height: rem(600);
    font-size: rem(16);
    &:focus {
      outline: none;
    }

    &.autosize {
      min-height: auto;
      max-height: inherit;
      padding-bottom: rem(8);
    }

    &:focus {
      outline: none;
      & + label {
        transform: translate(0, -40%);
        font-size: rem(14);
        border-radius: rem(0);
        font-weight: 400;
        color: map-get($color_text, "secondary");
      }
    }

    &:not(:placeholder-shown) {
      & + label {
        transform: translate(0, -40%);
        font-size: rem(14);
        border-radius: rem(0);
        font-weight: 400;
        color: map-get($color_text, "secondary");
      }
    }
  }

  label {
    position: absolute;
    z-index: 1;
    left: rem(12);
    top: rem(12);
    transition: 0.2s;
    border-radius: rem(0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .is-valid {
    display: flex;
    position: absolute;
    right: rem(12);
    top: rem(8);
  }

  .is-danger {
    display: flex;
    position: absolute;
    right: rem(10);
    top: rem(8);
  }

  &.default {
    border: rem(1) solid map-get($color_text, "default");

    label {
      color: map-get($color_text, "default");
    }
  }

  &.primary {
    border: rem(1) solid map-get($color_text, "default");
    color: map-get($color_text, "primary");
    min-height: rem(50);

    label {
      color: map-get($color_text, "secondary");
      font-weight: 400;
      font-size: rem(14);
    }
  }

  &.secondary {
    border: rem(1) solid map-get($color_green, "_0");

    label {
      color: map-get($color_green, "_0");
    }
  }

  &.danger {
    border: rem(1) solid map-get($color_red, "_0");

    label {
      color: map-get($color_red, "_0");
    }
  }

  &[disabled] {
    border: rem(1) solid map-get($color_gray, "_4");

    > label {
      color: map-get($color_gray, "_4");
    }

    textarea {
      pointer-events: none;
      color: map-get($color_gray, "_4");
    }
  }
}
