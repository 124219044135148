@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../../styles/breakpoints.scss";

* {
  box-sizing: border-box;
}

@mixin full {
  @media (max-width: $sm) {
    width: 100vw;
  }
}

.modal.promotion_modal {
  width: rem(560);
  @include full;

  > .header {
    padding: rem(24);
    border-bottom: 1px solid map-get($color_text, "disabled");
    > span {
      font-size: rem(24);
      font-weight: 500;
    }
  }
  > .body {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);
    background-color: white;
    justify-content: flex-start;
    padding: rem(24) rem(24) rem(100) rem(24);

    > div {
      width: 100%;
    }

    .container_fullwidth {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      &_half {
        margin-top: 0;
        width: rem(244);

        &_first {
          margin-bottom: rem(12);
          width: rem(244);
        }
      }

      input {
        font-size: rem(14);
      }
    }

    .picture-block {
      width: 100%;
      display: flex;
      flex-direction: column;

      .subtitle {
        margin-bottom: rem(8);
        font-weight: 500;
        color: map-get($color_text, "secondary");
      }
    }
  }
  > .footer {
    display: flex;
    align-items: center;
    padding: 0 rem(24);
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: rem(560);
    height: rem(80);
    background: map-get($color_white, "_0");
    @include full;
  }
}
