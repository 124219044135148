@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.users-page {
  @include screen-lg {
    max-width: 100vw;
  }

  .tables_body {
    overflow-x: scroll;
  }
}
.users_table {
  table {
    width: 100%;
  }

  &_heading {
    border-bottom: rem(1) solid map-get($color_gray, "_0");
    padding-bottom: rem(12);
    margin-bottom: rem(24);

    th {
      text-align: center;
      font-weight: 400;
      padding: rem(20) rem(16);

      &.username {
        text-align: start;
      }

      .skeleton {
        margin: 0 auto;
      }
    }

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  &_body {
    &:hover {
      background-color: map-get($color_gray, "_2");
    }

    &:not(:last-child) {
      border-bottom: rem(1) solid map-get($color_gray, "_0");
    }

    td {
      text-align: center;
      padding: rem(20) rem(16);
      min-width: rem(120);

      &.username {
        text-align: start;
      }

      button {
        width: 100%;
      }

      .skeleton {
        margin: 0 auto;
      }
    }
  }

  &_edit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &_iconWrapper:hover {
      svg {
        fill: map-get($color_basic, "_0");
      }
    }
  }
}


.users-filter {
  margin-bottom: rem(8);
}
