@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.modal {
  position: relative;
  z-index: 9;
  &Content {
    background-color: map-get($color_gray, "_3");
    position: absolute;
    bottom: 0%;
    right: 110%;
    width: 100%;
    border-radius: rem(10);
    opacity: 0;
    pointer-events: none;
    transform: translateX(50px);
    transition: all linear 0.2s;

    &[data-visible="true"] {
      transform: translateX(0px);
      opacity: 1;
      pointer-events: all;
    }
  }
  &Button {
    padding: rem(6) 0;
    cursor: pointer;
    transition: all linear 0.15s;
    &:hover {
      background-color: map-get($color_gray, "_2");
    }
  }
}
