@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

// .checkbox {
//   display: inline-flex;
//   align-items: center;
//   > div {
//     box-sizing: border-box;
//     width: rem(24);
//     height: rem(24);
//     border: solid rem(1.5) map-get($color_text, 'secondary');
//     background-color: map-get($color_text, 'default');
//     border-radius: 50%;
//     opacity: 0.3;
//     z-index: 1;
//     > input {
//       border: none;
//       width: 100%;
//       height: 100%;
//       opacity: 0;
//       z-index: 2;
//     }
//     &:checked {
//       border: solid rem(1.5) map-get($color_basic, '_0');
//       background-color: map-get($color_basic, '_0');
//     }
//   }
// }

.checkbox {
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: rem(24);
    height: rem(24);
    & + label {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      user-select: none;
    }
  }

  /* создание в label псевдоэлемента before со следующими стилями */
  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    width: rem(24);
    height: rem(24);
    border: solid rem(1.5) map-get($color_text, 'secondary');
    background-color: map-get($color_text, 'default');
    border-radius: 50%;
    opacity: 0.3;
  }
  /* стили для чекбокса, находящегося в состоянии checked */
  .custom-checkbox:checked + label::before {
    border: solid rem(1.5) map-get($color_basic, '_0');
    opacity: 1;
    background-color: map-get($color_basic, '_0');
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  .custom-checkbox:disabled + label::before {
    background-color: #e9ecef;
  }
}
