@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

.sidebar-dropdown-list {
  button {
    display: flex;
    transition: 0.1s;
    padding: rem(10) 0;
    padding-right: rem(28);
    padding-left: rem(28);
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;

    &:hover {
      background: map-get($color_basic, '_3');
    }

    > div {
      display: flex;
      align-items: center;
    }

    span {
      color: map-get($color_text, 'primary');
      font-size: rem(14);
      padding: 0 rem(16);
    }
  }

  .dropdown-arrow {
    height: rem(16);
    transition: 0.3s;
  }

  .dropdown-arrow-up {
    transform: rotate(180deg);
  }

  .dropdown-children {
    display: none;
    margin: 0;
    padding: 0;

    a {
      padding-left: rem(69);
      padding-top: rem(8);
      padding-bottom: rem(8);
      display: flex;
      transition: 0.1s;
      text-decoration: none;

      &[data-active='true'] {
        background: map-get($color_basic, '_3');
      }

      &:hover {
        background: map-get($color_basic, '_3');
      }

      span {
        color: map-get($color_text, 'primary');
        font-size: rem(14);
      }
    }
  }

  .dropdown-children-visible {
    display: block;
  }
}
