$rem_enabled: true;
$html_font_size: 16;

@function stripUnit($value) {
    @return calc($value / ($value * 0 + 1));
}
@function rem($pxValue) {
    @if $rem_enabled {
        @return #{calc(stripUnit($pxValue) / stripUnit($html_font_size))}rem;
    }
    @return #{stripUnit($pxValue)}px;
}
