@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.settings {
  min-width: 200px;
  width: 65%;
  padding: rem(20);

  @include screen-md {
    width: 85%;
  }
  @include screen-sm {
    width: 100%;
  }

  .modal {
    width: 100%;

    .setting_alert {
      color: map-get($color_basic, "_0.1");
      opacity: 0.8;
      margin: 0 rem(20) rem(20);
      text-align: center;
    }

    .settings_input_wrapper {
      display: flex;
      flex-direction: column;
      gap: rem(20);
      margin-bottom: rem(20);
    }
  }
}
