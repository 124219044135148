@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.settings {
  .modal {
    width: 100%;

    .setting_alert {
      color: map-get($color_basic, "_0.1");
      opacity: 0.8;
      margin: 0 rem(20) rem(20);
      text-align: center;
    }

    .settings_input_wrapper {
      display: flex;
      align-items: center;
      margin-bottom: rem(20);

      .settings_icon_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: rem(12);

        &:hover {
          svg {
            fill: map-get($color_basic, "_0");
          }
        }
      }
    }
  }
}
