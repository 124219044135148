@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.radius {
  max-width: 100%;

  &_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: rem(1) solid map-get($color_gray, "_1");

    @media (max-width: $sm) {
      flex-direction: column;
    }

    & h1 {
      margin-bottom: rem(16);
    }

    &_subheading {
      color: map-get($color_basic, "_3");
    }

    &_users {
      color: map-get($color_gray, "_4");
    }
  }

  &_button {
    @media (max-width: $sm) {
      margin-top: rem(16);
    }
  }

  &_table {
    width: clamp(rem(280), 100%, rem(560));
    &_paper {
      @media (max-width: $md) {
        overflow-x: scroll;
        max-width: 100vw;
      }
    }

    &.radius {
      margin-bottom: rem(36);
    }

    &_wrapper {
      overflow-x: auto;
      max-width: 100%;
      background-color: map-get($color_white, "_0");
      margin-top: rem(16);
      border-radius: rem(6);
      padding-bottom: rem(8);
    }

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_0");

      & th {
        text-align: center;
        font-weight: 400;
        padding: rem(20) rem(16);
      }

      .skeleton {
        margin: auto;
      }

      &_sort {
        opacity: 0;
        color: map-get($color_gray, "_3");
        font-weight: 400;
        margin-left: rem(8);
        cursor: pointer;
        transition: 0.3s all;

        &_active {
          opacity: 1;
        }
      }

      &_arrow {
        display: flex;

        &_active {
          display: flex;
          transform: rotate(180deg);
        }
      }

      &_cell {
        > div {
          position: inherit;
          display: flex;
          align-items: center;
          transition: 0.3s all;
        }

        &:hover .summary_table_heading_sort {
          opacity: 1;
        }
      }
    }

    &_body {
      border-bottom: rem(1) solid map-get($color_gray, "_0");

      &:last-child {
        border-bottom: none;
      }
      .align-right {
        text-align: right;
      }
      & td {
        text-align: center;
        padding: rem(16) rem(0);
        word-wrap: break-word;
        vertical-align: middle;
        display: table-cell;
        min-width: rem(50);

        .skeleton {
          margin: auto;
        }
      }
    }

    & &_edit {
      width: 10%;
      min-width: rem(110);
      cursor: pointer;

      &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }

    &_iconWrapper {
      display: flex;

      &:hover {
        svg {
          fill: map-get($color_basic, "_0");
        }
      }
    }
  }
}
