@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.promotions_table {
  max-width: 100%;

  .promotions_paper {
    @media (max-width: $md) {
      overflow-x: scroll;
      max-width: 100vw;
    }
  }

  table {
    min-width: 100%;
  }

  .empty {
    td {
      height: rem(200);
      vertical-align: middle;
      text-align: center;
    }
  }

  &_heading {
    width: 100%;
    border-bottom: rem(1) solid map-get($color_gray, "_0");
    padding-bottom: rem(12);
    margin-bottom: rem(24);

    th {
      text-align: center;
      font-weight: 400;
      padding: rem(20) rem(16);

      &.name {
        max-width: rem(90);
      }

      &.title {
        max-width: rem(216);
      }

      &.status {
        width: rem(152);
      }

      &.edit {
        width: rem(80);
      }

      .skeleton {
        margin: 0 auto;
      }
    }

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  &_body {
    &:hover {
      background-color: map-get($color_gray, "_2");
    }

    &:not(:last-child) {
      border-bottom: rem(1) solid map-get($color_gray, "_0");
    }

    td {
      text-align: center;
      padding: rem(20) rem(16);

      button {
        width: 100%;
      }

      .photo {
        width: 100px;
      }

      &.name {
        overflow-wrap: break-word;
        max-width: rem(100);
      }

      .skeleton {
        margin: 0 auto;
      }
    }
  }

  &_edit {
    vertical-align: inherit;

    &_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: rem(16);
    }

    &_iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_iconWrapper:hover {
      svg {
        fill: map-get($color_basic, "_0");
      }
    }
  }
}
