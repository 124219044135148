@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

$SIDEBAR_WIDTH: 258;

#--sidebar-root {
  z-index: 1;
  width: rem($SIDEBAR_WIDTH);
  flex-shrink: 0;
  background: map-get($color_basic, '_1');
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
  border-right: 1px solid map-get($color_gray, '_0');

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  p {
    color: map-get($color_text, 'primary');
    opacity: 0.9;
    margin: 0;
  }

  .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(8) rem(24);
    font-family: 'Roboto';
    text-decoration: none;
    font-weight: bold;
    font-size: rem(30);
    color: black;
  }

  > div {
    margin-bottom: rem(64);

    > p {
      font-size: rem(14);
      padding-top: rem(16);
      padding-bottom: rem(4);
      padding: rem(10) 0;
      padding-right: rem(28);
      padding-left: rem(28);
    }
  }

  .navigation-manager {
    display: flex;
    background: map-get($color_basic, '_0');
    padding: rem(10) rem(16);
    bottom: 0;
    z-index: 0;
    align-items: center;
    width: calc(#{rem($SIDEBAR_WIDTH)});
    position: fixed;
    text-decoration: none;

    .avatar {
      position: relative;
      display: flex;

      > div {
        width: rem(32);
        height: rem(32);
        border-radius: rem(40);
        padding: rem(4);
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: map-get($color_black, '_2');
        background-position: center;
        background-size: cover;
        color: map-get($color_white, '_0');
      }
    }

    .user-data {
      display: flex;
      flex-direction: column;
      padding: rem(4);
      margin-left: rem(8);
      margin-right: rem(16);
      overflow: hidden;

      .name,
      .mail {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .name {
        font-size: rem(14);
        color: map-get($color_white, '_0');
      }

      .mail {
        font-size: rem(12);
        color: map-get($color_white, '_0');
      }
    }
  }
}
