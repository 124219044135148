@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

.btn-root {
  height: fit-content;
  border-radius: rem(32);
  overflow: hidden;
  display: inline-flex;

  & {
    cursor: pointer;
    outline: 0;
    background-color: transparent;
    border: rem(1) solid transparent;

    min-width: rem(64);
    border-radius: rem(32);
    box-sizing: border-box;
    letter-spacing: 0.02857em;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: 100%;
      font-size: rem(14);
      color: map-get($color_black, '_1');
      font-weight: 700;
    }

    &.default {
      background-color: map-get($color_gray, '_1');

      &:hover {
        background-color: map-get($color_gray, '_3');
      }

      svg,
      path {
        fill: map-get($color_black, '_0');
      }

      &.outline {
        background: map-get($color_white, '_0');
        border: rem(1) solid map-get($color_black, '_2');

        &:hover {
          background-color: map-get($color_black, '_4');
        }

        svg,
        path {
          fill: map-get($color_black, '_0');
        }
      }
    }

    &.primary {
      background-color: map-get($color_basic, '_0');

      span {
        color: map-get($color_white, '_0');
      }

      svg,
      path {
        fill: map-get($color_white, '_0');
      }

      &:hover {
        background-color: map-get($color_basic, '_0.1');
      }

      &.outline {
        background: map-get($color_white, '_0');
        border: rem(1) solid map-get($color_black, '_1');

        span {
          color: map-get($color_black, '_1');
        }

        svg,
        path {
          fill: map-get($color_black, '_0');
        }

        &:hover {
          border: rem(1) solid map-get($color_black, '_0');
          background-color: map-get($color_gray, '_2');
        }
      }
    }

    &.secondary {
      background-color: map-get($color_green, '_0');

      span {
        color: map-get($color_white, '_0');
      }

      svg,
      path {
        fill: map-get($color_white, '_0');
      }

      &:hover {
        background-color: map-get($color_green, '_4');
      }

      &.outline {
        background: map-get($color_white, '_0');
        border: rem(1) solid map-get($color_green, '_0');

        span {
          color: map-get($color_green, '_0');
        }

        svg,
        path {
          fill: map-get($color_green, '_0');
        }

        &:hover {
          background-color: map-get($color_green, '_2');
          border: rem(1) solid map-get($color_green, '_3');
        }
      }
    }

    &.danger {
      background-color: map-get($color_red, '_0');

      span {
        color: map-get($color_white, '_0');
      }

      svg,
      path {
        fill: map-get($color_white, '_0');
      }

      &:hover {
        background-color: map-get($color_red, '_4');
      }

      &.outline {
        background: map-get($color_white, '_0');
        border: rem(1) solid map-get($color_red, '_1');

        span {
          color: map-get($color_red, '_0');
        }

        svg,
        path {
          fill: map-get($color_red, '_0');
        }

        &:hover {
          background-color: map-get($color_red, '_3');
          border: rem(1) solid map-get($color_red, '_1');
        }
      }
    }

    &[disabled] {
      pointer-events: none;
      background-color: map-get($color_gray, '_2');

      span {
        color: map-get($color_gray, '_5');
      }

      svg,
      path {
        fill: map-get($color_gray, '_5');
      }

      &.outline {
        background: map-get($color_white, '_0');
        border: rem(1) solid map-get($color_gray, '_2');

        span {
          color: map-get($color_gray, '_5');
        }

        svg,
        path {
          fill: map-get($color_gray, '_5');
        }
      }
    }

    .opacity {
      opacity: 0.2;
    }

    .loading {
      position: absolute;
      display: flex;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      height: 0;

      &.right {
        padding-left: rem(8);
      }

      &.left {
        padding-right: rem(8);
        transform: translateY(1.6px);
      }
    }

    &.small {
      padding: rem(6) rem(16);

      svg {
        width: rem(18);
        height: rem(18);
      }
    }

    &.medium {
      padding: rem(8) rem(24);

      svg {
        width: rem(22);
        height: rem(22);
      }
    }

    &.large {
      padding: rem(18) rem(32);

      svg {
        width: rem(26);
        height: rem(26);
      }
    }
  }
}
