@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.user_container {
  width: 100%;
  .row {
    display: flex;
    gap: rem(8);
    margin-bottom: rem(20);

    span:last-child {
      opacity: 0.8;
    }
  }

  .user_table {
    width: 100%;

    table {
      width: 100%;
    }

    .empty {
      td {
        height: rem(200);
        vertical-align: middle;
        text-align: center;
      }
    }

    &_heading {
      border-bottom: rem(1) solid map-get($color_gray, "_0");
      padding-bottom: rem(12);
      margin-bottom: rem(24);

      th {
        text-align: center;
        font-weight: 400;
        padding: rem(20) rem(16);
      }

      @media (max-width: $sm) {
        flex-direction: column;
      }
    }

    &_body {
      &:not(:last-child) {
        border-bottom: rem(1) solid map-get($color_gray, "_0");
      }

      td {
        text-align: center;
        padding: rem(20) rem(16);

        &:not(:last-child) {
          min-width: rem(160);
        }

        &:last-child {
          width: 100%;
        }

        .link {
          color: rgba(0, 0, 0, 0.87);
          opacity: 0.8;
        }
      }
    }

    &_edit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          margin-right: rem(8);
        }

        span {
          white-space: nowrap;
        }
      }

      &_iconWrapper {
        display: flex;
        align-items: center;

        &:hover {
          svg {
            fill: map-get($color_basic, "_0");
          }
        }
      }
    }
  }
}

.drawer-user {
  .title-for-drawer {
    padding: rem(24);
    font-size: rem(24);
    font-weight: 500;
    line-height: rem(36);
    border-bottom: 1px solid map-get($color_text, "disabled");
  }

  .container-for-survey {
    padding: rem(20);
    overflow: scroll;

    .text-survey {
      margin-top: rem(15);
    }
  }

  .container-for-reports {
    padding: rem(0) rem(20) rem(10) rem(20);
  }

  .text {
    margin-top: rem(10);
    border-bottom: 1px solid map-get($color_text, "disabled");
  }

  > .footer {
    display: flex;
    align-items: center;
    padding: 0 rem(24);
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: rem(560);
    height: rem(80);
    background: map-get($color_white, "_0");
  }
}

.container-for-header {
  display: flex;
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  flex-direction: "row";
  margin-bottom: rem(30);
  cursor: pointer;

  .active-bottom {
    padding: rem(10);
    border-bottom: rem(2) solid #ee0045;
  }
  .not-active-bottom {
    padding: rem(10);
  }
}

.user_page_paper {
  @include screen-md {
    overflow-x: scroll;
  }
}
