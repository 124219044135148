@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "../../styles/mainTitle.scss";

.we-remember-you-page {
  .container-for-header {
    display: flex;
    position: relative;
    width: 98%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    flex-direction: "row";
    margin-bottom: rem(30);

    .active-bottom {
      padding: rem(10);
      border-bottom: rem(2) solid #ee0045;
    }
    .not-active-bottom {
      padding: rem(10);
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: rem(20);
  width: 100%;

  .paper-skeleton-for-post {
    height: rem(300);
  }

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  > * {
    width: calc(50% - #{rem(30)});
    margin-right: rem(20);
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  .paper {
    position: relative;
    padding: rem(12) rem(0);

    .delete {
      position: absolute;
      right: rem(5);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    .button {
      position: absolute;
      bottom: rem(7);
      width: 100%;
      display: flex;
      padding-top: rem(10);

      .approve {
        margin-left: rem(10);
        justify-content: center;
      }
    }
  }
  .content-posts-for-approval {
    display: flex;
    flex-wrap: wrap;
    padding: rem(0) rem(16) rem(10) rem(16);
    margin-bottom: rem(40);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;

    span {
      opacity: 0.8;
    }

    h4 {
      margin-top: rem(15);
    }
    .container-for-photo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(270);
      height: rem(250);
      padding-right: rem(15);
      .photo {
        max-width: rem(270);
        max-height: rem(250);
        border-radius: rem(7);
      }
    }

    .paper-skeleton-for-post {
      height: rem(200);
    }

    .container-for-text {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: rem(10);
      .flex {
        display: flex;
        flex-direction: column;
        gap: rem(5);
        align-items: center;
        justify-content: center;
      }
    }
  }
}
