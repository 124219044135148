@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

@mixin touchable-conatainer {
  background-color: transparent;
  width: 100%;
  text-align: left;
  padding: rem(6) rem(12);
  border: none;
  transition: 0.2s;
}

#--header-search {
  position: relative;

  .text-field-box {
    display: flex;
    align-items: center;
    padding: 0 rem(12);
    transition: 0.3s;
    border-radius: rem(4);
    
    &:hover {
      background-color: map-get($color_gray, "_0");
    }

    @media (max-width: $sm) {
      display: none;
    }

    input {
      padding: rem(10);
      font-size: rem(14);
      outline: none;
      border: none;
      color: map-get($color_black, "_0");
      transition: 0.3s;
      margin-left: rem(-30);
      margin-right: rem(-10);
      padding-left: rem(48);
      background: transparent;

      &::placeholder {
        color: map-get($color_gray, "_4");
      }
    } 
  }

  .filled {
    background-color: map-get($color_gray, "_0");
  }
}

#--header-search + .dropdown {
  background-color: map-get($color_white, "_0");
  position: absolute;
  top: rem(49);
  width: rem(300);
  box-shadow: 0 rem(6) rem(7) 0 map-get($color_black, "_4");
  padding: rem(2) 0;
  border-bottom-left-radius: rem(4);
  border-bottom-right-radius: rem(4);
  border-top: rem(1) solid map-get($color_gray, "_0");

  button {
    @include touchable-conatainer;

    &:hover {
      background: map-get($color_gray, "_0");
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    padding-top: rem(5);
    align-items: center;
  }

  .empty {
    @include touchable-conatainer;

    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .triangle {
    position: absolute;
    top: rem(-13);
    left: rem(10);
  }
}

#--header-search ~ .btn-root {
  margin-left: rem(12);
}