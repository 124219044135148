@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.toggle {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  padding: rem(2) 0;
  margin-left: rem(-9);
  text-align: left;

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &:active {
    color: inherit;
    background-color: transparent;
  }
}