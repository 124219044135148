@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../../../styles/breakpoints.scss";

* {
  box-sizing: border-box;
}
.modal {
  width: rem(400);
  max-width: 100vw;
  > .header {
    padding: rem(24);
    border-bottom: 1px solid map-get($color_text, "disabled");
    > span {
      font-size: rem(24);
      font-weight: 500;
    }
  }
  > .body {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);
    background-color: white;
    justify-content: flex-start;
    padding: rem(24);
  }
  > .footer {
    display: flex;
    align-items: center;
    padding: 0 rem(24);
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: rem(400);
    max-width: 100vw;
    height: rem(80);
  }
}
