@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

@mixin simple-circle {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  padding: rem(12);
  border-radius: rem(50);
  transition: 0.2s;
  cursor: pointer;
}

@mixin circle-hover {
  background-color: map-get($color_gray, "_2");
}


@mixin en {
  background-image: url('../../assets/images/uk.png');
}


#--header-root {
  padding: rem(8) rem(20);
  max-height: rem(49);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: 0 rem(2) rem(3) map-get($color_black, "_4");
  background-color: map-get($color_white, "_0");

  @media (max-width: $lg) {
    padding: rem(8) rem(20);
  }

  @media (max-width: $sm) {
    padding: rem(8) rem(4);
  }

  .burger {
    button {
      @include simple-circle;

      &:hover {
        @include circle-hover;
      }

      @media (min-width: $lg) {
        display: none;
      }
    }
  }

  .content {
    justify-content: space-between;
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: $lg) {
      justify-content: flex-end;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .language {
        @include simple-circle;

        &:hover {
          @include circle-hover;
        }
        .en {
          @include en;
        }
        .language-image {
          background-size: 160%;
          background-position: center;
          border-radius: rem(20);
          width: rem(22);
          height: rem(22);
        }
      }

      .logout {
        @include simple-circle;

        &:hover {
          @include circle-hover;
        }
      }
    }
  }
}
