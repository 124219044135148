@import '../../styles/colors.scss';
@import '../../styles/rem.scss';
@import '../../styles/breakpoints.scss';


#--auth-root {
  display: flex;

  .container {
    width: 57%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: $lg) {
      width: 100%;
    }

    .auth-box {
      width: rem(400);
      padding: rem(20);
      margin-top: rem(12);

      h1 {
        font-size: rem(24);
        font-weight: 400;
        margin-bottom: rem(8);
      }

      @media (max-width: $sm) {
        min-width: auto;
        width: calc(100% - #{rem(48)});
      }

      &.disabled {
        pointer-events: none;
      }

      .fields {
        margin-top: rem(16);

        >div {
          margin-bottom: rem(16);
        }
      }

      .error {
        color: map-get($color_basic, '_0')
      }

      .enter {
        margin-top: rem(18);

        div,
        button {
          width: 100%;
        }
      }

      &~#--auth-languages {
        margin-top: rem(12);
        width: 57%;
        display: flex;
        justify-content: center;
        gap: rem(12);

        .language-box {
          background-color: map-get($color_basic, '_3');
          width: rem(44);
          height: rem(44);
          outline: none;
          border: rem(1) solid map-get($color_basic, '_3');
          border-radius: rem(50);
          transition: 0.2s;
          cursor: pointer;

          >span {
            font-size: rem(18);
            font-weight: 400;
            align-items: center;
            vertical-align: middle;
          }

          &:hover {
            background-color: map-get($color_gray, '_2');
          }

          .language-basic {
            background-size: 160%;
            background-position: center;
            border-radius: rem(20);
            width: rem(22);
            height: rem(22);
            opacity: 0.4;
            transition: 0.2s;
          }
        }

        .selected {
          background-color: map-get($color_basic, '_0');
          color: #fff;
          opacity: 1;
        }
      }
    }

    .forgot-password {
      margin-top: rem(8);
      opacity: 0.8;
      width: rem(400);
      text-align: right;
      font-size: rem(14);
      cursor: pointer;
      padding-right: rem(16);

      &:hover {
        opacity: 1;
      }
    }
  }

  .background {
    width: 43%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    > img {
      height: 100%;
    }

    .title {
      position: absolute;
      bottom: rem(90);
      left: rem(90);
      line-height: rem(60);

      h1 {
        font-size: 400%;
        line-height: rem(70);
      }

      img {
        width: 400px;
      }
    }
  }

}
