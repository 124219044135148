@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "../../styles/mainTitle.scss";

.container-for-header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: rem(30);

  > div {
    margin-right: rem(10);
    margin-bottom: rem(10);
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10);
  }

  .input-date {
    margin-right: rem(10);
  }
  .active-bottom {
    padding: rem(10);
    border-bottom: rem(2) solid #ee0045;
  }

  .not-active-bottom {
    padding: rem(10);
  }

  .button {
    text-align: center;
    align-self: center;
    margin: rem(10) 0;
    width: 116px;
    position: relative;
  }
}

#button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  .button-all {
    width: 42%;
    margin: rem(23);
  }

  .button-reported {
    width: 40%;
    margin: rem(23);
    margin-left: rem(33);
  }

  .filter {
    position: absolute;
    right: rem(80);
    top: rem(25);
    border: none;
    opacity: 0.5;
  }
}

.container-for-survey {
  margin-top: rem(12);
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  > * {
    width: calc(50% - #{rem(40)});
    margin-right: rem(12);
    margin-bottom: rem(12);

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  .paper {
    position: relative;
    padding: rem(0) rem(0);

    .delete {
      position: absolute;
      right: rem(5);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    .content-all {
      padding-left: rem(16);
      padding-right: rem(40);
      padding-bottom: rem(10);
      .info {
        border-bottom: 1px solid map-get($color_text, "disabled");
      }

      .style-for-span {
        opacity: 0.8;
      }

      .link {
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.8;
        margin-bottom: rem(10);
      }

      .text {
        margin-top: rem(10);
      }
    }
  }
}
.paper-skeleton-for-survey {
  height: rem(400);
}
.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: rem(40);
}
