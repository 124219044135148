@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

#faq-container {
  display: flex;
  flex-wrap: wrap;
  gap: rem(12);
  width: 80%;

  @media (max-width: $md) {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  > * {
    width: 100%;

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      // width: auto;
    }
  }

  .paper {
    position: relative;
    padding: rem(12) rem(0);
    overflow: none;

    .delete {
      position: absolute;
      right: rem(5);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    .edit {
      position: absolute;
      right: rem(44);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    .drag {
      position: absolute;
      left: rem(5);
      top: rem(5);
      bottom: rem(5);
      border: none;
      opacity: 0.2;
      border-radius: rem(10);
      width: rem(40);
    }

    .questions-button {
      position: absolute;
      width: rem(24);
      height: rem(30);
      border-radius: rem(10);
      opacity: 0.2;

      &.delete {
        right: rem(5);
        top: rem(5);
        width: rem(24);
      }

      &.edit {
        right: rem(44);
        top: rem(5);
        width: rem(24);
      }
    }

    .content-all {
      padding-left: rem(56);
      margin-right: rem(40);

      .text {
        opacity: 0.8;
        overflow-wrap: break-word;
      }

      .link {
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.8;
      }

      .title,
      .skeleton {
        margin-top: rem(16);
      }
    }

    .button {
      position: absolute;
      bottom: rem(10);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      display: flex;
      padding-top: rem(10);

      .openReports {
        margin-left: rem(10);
        justify-content: center;
      }
    }
  }
}
