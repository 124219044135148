@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "../../styles/mainTitle.scss";

@mixin pad($t, $r, $b, $l) {
  padding: rem($t) rem($r) rem($b) rem($l);
}

.alerts-page {
  .container-for-header {
    display: flex;
    width: 95%;
    left: 0;
    right: 0;

    flex-direction: row;
    margin-bottom: rem(30);

    @media (max-width: $xxl) {
      padding-right: rem(60);
    }

    @media (max-width: $lg) {
      padding-left: rem(60);
    }

    .active-bottom {
      padding: rem(10);
      border-bottom: rem(2) solid #ee0045;
      display: flex;
      flex-direction: row;
      gap: rem(8);
      align-items: center;
    }

    .not-active-bottom {
      padding: rem(10);
      display: flex;
      flex-direction: row;
      gap: rem(8);
      align-items: center;

    }

    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .filter {
    margin-bottom: rem(10);
  }

  .container-for-alerts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: $md) {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }

    > * {
      width: calc(50% - #{rem(20)});
      margin-right: rem(12);
      margin-bottom: rem(12);

      @media (max-width: $md) {
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }
    }

    .paper {
      position: relative;
      padding: rem(12) rem(0);

      .alert__img {
        width: 100%;
        min-height: 150px;
        margin: rem(15) 0 rem(5) 0;
        border-radius: rem(10);
      }

      .delete {
        position: absolute;
        right: rem(5);
        top: rem(5);
        border: none;
        opacity: 0.5;
        border-radius: rem(10);
        height: rem(30);

        .icon {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .content-all {
        @include pad(0, 40, 10, 16);

        span {
          opacity: 0.8;
        }

        a {
          color: rgba(0, 0, 0, 0.87);
          opacity: 0.8;
        }

        h4 {
          margin-top: rem(15);
        }
      }

      .content-report-alert {
        margin-bottom: rem(50);
        padding-left: rem(16);
        padding-right: rem(40);

        a {
          color: rgba(0, 0, 0, 0.87);
          opacity: 0.8;
        }

        .style-for-span {
          opacity: 0.8;
        }

        .text {
          margin-top: rem(15);
        }
      }

      .button {
        position: absolute;
        bottom: rem(7);
        display: flex;

        .openReports {
          margin-left: rem(10);
          justify-content: center;
        }
      }
    }
  }

  .paper-skeleton-for-alert {
    height: rem(260);
  }

  .border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: rem(40);
  }
}

.drawer-alerts {
  width: rem(350);
  @include screen-sm {
    width: 100vw;
  }

  h1 {
    padding: rem(24);
    font-size: rem(24);
    font-weight: 500;
    line-height: rem(36);
    border-bottom: 1px solid map-get($color_text, "disabled");
  }

  .container-for-reports {
    @include pad(0, 20, 10, 20);
  }

  .text {
    margin-top: rem(10);
    border-bottom: 1px solid map-get($color_text, "disabled");
  }

  > .footer {
    display: flex;
    align-items: center;
    padding: 0 rem(24);
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: inherit;
    height: rem(80);
    background: map-get($color_white, "_0");
  }
}
