@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.skeleton {
  border-radius: rem(4);
  position: relative;
  overflow: hidden;
  background-color: map-get($color_gray, '_3')!important;
  opacity: 0.2; 
  display: block;
  width: 100%;

  > * {
    visibility: hidden;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: rem(-150);
    top: 0;
    height: 100%;
    width: rem(150);
    background: linear-gradient(to right, transparent 0%, map-get($color_white, '_2') 50%, transparent 100%);
    animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }

  @keyframes load {
    from {
      left: rem(-150);
    }
    to {
      left: 100%;
    }
  }
}
