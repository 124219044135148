@import '../../../styles/colors.scss';
@import '../../../styles/rem.scss';

* {
  box-sizing: border-box;
}
.modal {
  width: rem(560);
  > .header {
    padding: rem(24);
    border-bottom: 1px solid map-get($color_text, 'disabled');
    > span {
      font-size: rem(24);
      font-weight: 500;
    }
  }
  > .body {
    display: flex;
    flex-wrap: wrap;
    gap: rem(12);
    background-color: white;
    justify-content: flex-start;
    padding: rem(24);

    > div {
      width: 100%;
    }

    textarea { 
      min-height: rem(220);
    }

    .container_fullwidth {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      &_half {
        margin-top: rem(16);
        width: rem(244);
    
        &_first {
          margin-top: 0;
          width: rem(244);
        }
      }
      
      input {
        font-size: rem(14);
      }
    }
  }
  > .footer {
    display: flex;
    align-items: center;
    padding: 0 rem(24);
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: rem(560);
    height: rem(80);
    background: map-get($color_white, '_0' );
  }
}
