@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";

.create-notification-container {
  position: relative;
  width: rem(560);
  padding: rem(24);

  @media (max-width: $sm) {
    width: 100vw;
  }

  .input {
    margin: rem(10) rem(0);
  }
  .input-text-message {
    margin-top: rem(10);
  }

  > .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: rem(512);
    height: rem(80);
    background: map-get($color_white, "_0");
    @media (max-width: $sm) {
      width: 88vw;
    }
  }
}
