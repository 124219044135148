@import "./rem.scss";

$xs: rem(0);
$sm: rem(576);
$md: rem(768);
$lg: rem(992);
$xl: rem(1200);
$xxl: rem(1440);

@mixin screen-xs {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin screen-sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin screen-md {
  @media (max-width: $md) {
    @content;
  }
}

@mixin screen-lg {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin screen-xl {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin screen-xxl {
  @media (min-width: $xxl) {
    @content;
  }
} ;
