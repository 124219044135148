@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

body .MuiPaper-elevation24 {
  box-shadow: none;
}

.modal-root {
  height: fit-content;
  overflow-x: hidden;

  > .header {
    margin-bottom: rem(0);
    border-bottom: rem(1) solid map-get($color_black, "_3");
    padding-left: rem(24);
    padding-right: rem(24);
    padding-top: rem(16);
    padding-bottom: rem(16);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: map-get($color_white, '_0');

    h2 {
      font-weight: 500;
      font-size: rem(24);
      margin: 0;
    }

    button {
      padding: 0;
      height: fit-content;
      margin-left: auto;
      background-color: transparent;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    background: map-get($color_black, "_4");
  }
}
