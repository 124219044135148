@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.select-container {
  border: 0;
  border-radius: rem(8);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  background: map-get($color_white, "_0");
  box-sizing: border-box;

  select {
    width: 100%;
    border: 0;
    height: inherit;
    border-radius: rem(4);
    display: inline-block;
    padding-left: rem(12);
    padding-right: rem(60);
    margin: 0;
    -webkit-appearance: none;
    background-color: transparent;
    -moz-appearance: none;
    font: inherit;

    option:disabled {
      background: map-get($color_gray, "_3");
      color: map-get($color_white, "_0");
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      top: rem(-10);
      font-size: rem(12);
      background: map-get($color_white, "_0");
      padding: 0 rem(4);
      left: rem(8);
    }
  }

  select + label {
    position: absolute;
    z-index: 1;
    top: rem(12);
    left: 0;
    font-weight: 300;
    font-size: rem(13);
    left: rem(12);
    transition: 0.2s;
    border-radius: rem(4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icons {
    display: flex;
    position: absolute;
    right: rem(12);
    align-items: center;

    .arrow {
      display: flex;
    }

    .is-valid {
      padding: rem(6) 0 rem(6) rem(12);
      display: flex;
      border-left: rem(1) solid map-get($color_gray, "_4");
      margin-left: rem(12);
    }
  }

  &.default {
    border: rem(1) solid map-get($color_text, 'default');

    > label {
      color: map-get($color_gray, "_4");
    }
  }

  &.primary {
    border: rem(1) solid map-get($color_black, "_1");

    > label {
      color: map-get($color_black, "_1");
    }
  }

  &.secondary {
    border: rem(1) solid map-get($color_green, "_0");

    > label {
      color: map-get($color_green, "_0");
    }
  }

  &[disabled] {
    border: rem(1) solid map-get($color_gray, "_4");

    > label  {
      color: map-get($color_gray, "_4");
    }

    > select {
      pointer-events: none;
      color: map-get($color_gray, "_4");
      background: transparent
    }
  }

  &.small {
    height: rem(34);
  }

  &.medium {
    height: rem(38);
  }

  &.large {
    height: rem(42);
  }
}
