@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

@mixin h4 {
  h4 {
    font-weight: 500;
    margin-bottom: rem(4);
  }
}

@mixin article {
  article {
    margin-bottom: rem(12);
  }
}
