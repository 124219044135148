@import '../../styles/colors.scss';
@import '../../styles/rem.scss';

.editable-textarea-container {
  width: 100%;

  .quill {
    > div:first-child {
      border: none;
      background: none;
      margin-bottom: 15px;
    }

    > div:last-child {
      border: none !important;
      border: rem(1) solid map-get($color_text, 'default') !important;
      border-radius: rem(12);
      background: map-get($color_white, '_0');
      min-height: rem(160);
      font-family: 'Roboto';

      &.ql-disabled {
        background: map-get($color_white, '_1');
        border: rem(1) solid map-get($color_white, '_1') !important;
        border-radius: rem(12);
      }

      p, strong, em {
        font-size: rem(16);
      }
    }
  }

  .ql-snow.ql-toolbar button {
    height: rem(30);
    width: rem(30);

    &:hover, &:focus {
      svg {
        * {
          stroke: map-get($color_basic, '_0')
        }
      }
    }
  }

  &[disabled] {
    > label {
      color: map-get($color_gray, '_4');
    }

    input {
      pointer-events: none;
      color: map-get($color_gray, '_4');
    }

    .ql-snow.ql-toolbar button {
      &:hover, &:focus {
        svg {
          * {
            stroke: #444;
          }
        }
      }
    }  
  }
}
