@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";
@import "../styles.scss";
@import "../../../styles/breakpoints.scss";

#--modals {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-12);
  margin-right: rem(-12);

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  > * {
    width: calc(50% - #{rem(56)});
    margin-left: rem(12);
    margin-right: rem(12);
    margin-bottom: rem(24);

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  @include h4;
  @include article;

  .confirm-modal {
    aside {
      > :not(:last-child) {
        margin-bottom: rem(16);
      }
    }
  }
}

#--modal-example {
  box-sizing: border-box;
  padding: rem(24);

  .bottom {
    position: sticky;
    bottom: 0;
    padding-top: rem(24);
    padding-bottom: rem(24);
    margin-bottom: rem(-24);
    background: map-get($color_white, "_0");
    border-top: rem(1) solid map-get($color_gray, '_1');
    margin-left: rem(-24);
    margin-right: rem(-24);
    padding-left: rem(24);
    padding-right: rem(24);
  }
}