@import '../../styles/colors.scss';
@import '../../styles/rem.scss';


.title-container {
  display: flex;
  justify-content: space-between;
  .title {
    padding-left: rem(10);
    color: map-get($color_text, 'secondary');
    font-weight: 400;
  }
  .counter-simbols{
    padding-right: rem(10);
    color: map-get($color_text, 'secondary');
    font-weight: 400;
  }
}
