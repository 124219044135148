@import "../../../styles/colors.scss";
@import "../../../styles/rem.scss";

#--icons-root {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-12);
  margin-right: rem(-12);

  .icon-container {
    width: calc(11.11% - #{rem(40)});
    margin-left: rem(12);
    margin-right: rem(12);
    margin-bottom: rem(24);
    display: flex;
    padding-left: rem(8);
    padding-right: rem(8);
    flex-direction: column;
    align-items: center;
    padding-bottom: rem(16);

    > * {
      display: block;
    }

    > input {
      border: 0;
      text-align: center;
      margin-top: rem(12);
      font-size: rem(12);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      width: 100%;
      cursor: copy;
    }
  }
}