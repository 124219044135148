@import "../../styles/colors.scss";
@import "../../styles/rem.scss";


.cust-title-container {
  margin-bottom: rem(16);


  .title {
      color: map-get($color_gray, "_6" );
      margin-bottom: rem(16);
  }

  .required {
      color: map-get($color_gray, "_3" );
      margin-bottom: rem(16);
  }
}

.cust-uploading-files {
  height: rem(160);
  width: 100%;
  border: rem(1) solid map-get($color_text, 'default');
  border-radius: rem(4);
  position: relative;

  &.warning {
    border: 1px solid map-get($color_red, '_4');
  }
  
  &[disabled] {
    *,
    + aside {
      pointer-events: none;
    }

    .dropzone,
    + aside {
      opacity: 0.8;
    }
  }

  .dropzone {
    display: flex;
    width: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;

    > .circle {
      border-radius: rem(50);
    }

    > .description {
      margin-top: rem(8);

      p {
        margin-top: 0;
      }
    }

    > p {
      margin-top: rem(8);

      strong {
        font-weight: 400;
      }
    }
  }

  + aside {
    display: flex;
    margin: rem(-8);
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-direction: column;
      width: calc(33.33% - #{rem(16)});
      margin: rem(8);
      box-shadow: 0 0 rem(4) rem(0) map-get($color_black, '_3');
      border-radius: rem(4);
      overflow: hidden;
          
      p {
        text-align: center;
        padding: rem(16);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      figure {
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        box-sizing: border-box;
        border-bottom: rem(1) solid map-get($color_gray, '_0');

        &.docx,
        &.doc,
        &.pdf {
          background-size: 70%;
        }

        &.docx {
          background-image: url('../../assets/images/docx.svg');
        }

        &.doc {
          background-image: url('../../assets/images/doc.svg');
        }

        &.pdf {
          background-image: url('../../assets/images/pdf.svg');
        }

        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        &:hover {
          > .hidden-section {
            opacity: 1;
          }
        }

        .hidden-section {
          transition: 0.2s;
          background: map-get($color_black, '_2');
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button {
            background-color: transparent;
            border: 0;
            position: absolute;
            top: rem(12);
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              transition: 0.2s;
            }

            &:hover {
              svg {
                fill: map-get($color_white, '_0');
              }
            }
          }
        }
      }
    }
  }

  .container-description {
    display: flex;
    align-items: center;
    justify-content: center;

    .span-color-gray {
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      color: map-get($color_gray, '_4');
    }

    .container-for-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > div {
    height: 100%;
  }
}
.buttons-container {
  border-radius: 50%;
  background-color: #E3E3E3;
  position: absolute;
  top: rem(-10);
  right: rem(-10);
  width: rem(20);
  height: rem(20);
  &:hover {
    cursor: pointer;
  }
  display: flex; align-items: center; justify-content: center;
  margin-left: rem(36);

  .button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .delete {
    font-size: rem(14);
    color: map-get($color_basic, '_0');
    font-weight: 400;

    &:hover {
      margin-bottom: rem(4);
      transition-property: margin-bottom;
      transition-duration: .2s;
      transition-timing-function: ease;
      color: map-get($color_black, '_1');
      font-weight: 400;
      color: map-get($color_red, '_0');
    }
    &[disabled] {
      color: map-get($color_gray, '_1');
    }
  }
}
.file-information-container {
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .file-container{
    display: flex;
    gap: rem(16);
    height: 100%;
    width: 100%;

    .image-container {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      
      .image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: rem(8);
      }
      .delete {
        position: absolute;
        width: rem(40);
        height: rem(8);
        &:hover {
        position: absolute;
        width: rem(40);
        height: rem(8);
        background-color: map-get($color_red, '_4');
        }
      }
    }
  }

  
}