@import "../../styles/colors.scss";
@import "../../styles/rem.scss";
@import "../../styles/breakpoints.scss";
@import "../../styles/mainTitle.scss";

.container-for-header {
  display: flex;
  position: relative;
  width: 89%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  flex-direction: "row";
  margin-bottom: rem(30);

  .active-bottom {
    padding: rem(10);
    border-bottom: rem(2) solid #ee0045;
  }
  .not-active-bottom {
    padding: rem(10);
  }

  .button {
    position: absolute;
    bottom: rem(3);
    right: 0;
    width: 100px;
  }
}

.container-for-notifications {
  display: flex;
  flex-wrap: wrap;
  width: 95%;

  @media (max-width: $md) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  > * {
    width: calc(50% - #{rem(40)});
    margin-right: rem(12);
    margin-bottom: rem(12);

    @media (max-width: $md) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  .paper {
    position: relative;
    padding: rem(12) rem(0);
    overflow: none;

    .delete {
      position: absolute;
      right: rem(5);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    .edit {
      position: absolute;
      right: rem(44);
      top: rem(5);
      border: none;
      opacity: 0.5;
      border-radius: rem(10);
      height: rem(30);
    }

    > div {
      padding-left: rem(16);
      margin-right: rem(44);

      span {
        opacity: 0.8;
        overflow-wrap: break-word;
      }

      h4 {
        margin-top: rem(15);
      }
    }

    .button {
      position: absolute;
      bottom: rem(10);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      display: flex;
      padding-top: rem(10);
    }
  }
}
.drawer {
  h1 {
    padding: rem(24);
    font-size: rem(24);
    font-weight: 500;
    line-height: rem(36);
    border-bottom: 1px solid map-get($color_text, "disabled");
  }
}
.paper-skeleton-for-notification-sms {
  height: rem(100);
}
.paper-skeleton-for-notification-other {
  height: rem(150);
}
