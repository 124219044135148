@import '../../styles/colors.scss';
@import '../../styles/rem.scss';
@import '../../styles/breakpoints.scss';

.table-container {
  &.secondary {
    box-shadow: none;
    border: rem(1) solid map-get($color_black, '_3');

    tr > td:not(:last-child) {
      border-right: rem(1) solid map-get($color_black, '_3');
    }

    tr > td:first-child {
      background-color: map-get($color_black, '_4');
    }
  }

  aside {
    margin: rem(-16);
    margin-bottom: rem(-24);
    overflow-x: auto;
    @media (max-width: $lg) {
      overflow-x: auto;
      overflow-y: hidden;
    }

    table {
      width: 100%;
      display: table;
      border-spacing: 0;
      border-collapse: collapse;

      @media (max-width: $lg) {
        min-width: rem(1100);
      }

      > thead {
        display: table-header-group;
      }

      > thead > tr > td {
        color: map-get($color_black, '_1');
        font-weight: 400;
      }

      tbody > tr > td,
      thead > tr > td {
        display: table-cell;
        padding: rem(16);
        font-size: rem(14);
        font-weight: 300;
        line-height: 1.2;
        border-bottom: rem(1) solid map-get($color_white, '_2');
        vertical-align: inherit;
      }

      thead > tr > td {
        font-weight: 400;
        padding: rem(22) rem(16);
        vertical-align: initial;
      }

      thead > tr,
      tbody > tr {
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
      }

      tbody > tr:last-child > td {
        border: 0;
      }
    }
  }
}
