@import "../../styles/colors.scss";
@import "../../styles/rem.scss";

.radio-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: rem(-9);

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}